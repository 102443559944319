import "../DeviceInfoCard/DeviceInfoCard.scss";

import EditIcon from "@mui/icons-material/Edit";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { BufferedAction } from "gardenspadejs/dist/general";
import { withSnackbar } from "notistack";
import React from "react";

import FocusContext from "../../../../services/mapManagement/FocusContext";
import VarietalAvatar from "../../../icons/VarietalAvatar";
import { FunctionalInfoCardEffect } from "../FunctionalInfoCard";
import { INFO_CARD_TYPES } from "../InfoCardTypes";
import { ZoneInfoCardBody } from "./ZoneInfoCardBody";

export const _FunctionalZoneInfoCard = React.memo((props) => {
    const bufferedSaveHandler = React.useRef(undefined);
    let name = `Zone ${props.Target.id}`;
    const focusContextGenerator = React.useCallback(() => {
        const newFocusContext = new FocusContext();
        newFocusContext.defaultFunction = (me) => {
            let relDevice = false;
            if (me && me.model && me.model.connectedZones && me.model.connectedZones.includes(props.Target)) {
                relDevice = true;
            } else if (me && me.model && me.model.zonesByValve && me.model.zonesByValve.includes(props.Target)) {
                relDevice = true;
            } else if (me && me.model && me.model === props.Target) {
                relDevice = true;
            } else if (me && me.model && me.model.zone === props.Target) {
                relDevice = true;
            }
            if (!relDevice) {
                return "inactive";
            }
            return "active";
        };
        newFocusContext.name = `zone focus context for ${props.Target.name || "unnamed zone"}`;
        return newFocusContext;
    }, []);
    if (!bufferedSaveHandler.current) {
        bufferedSaveHandler.current = new BufferedAction(
            (arg1) => {
                try {
                    props.Target.edit(undefined, arg1)
                        .then(() => {
                            props.enqueueSnackbar("Saved zone name");
                        })
                        .catch((e) => {
                            console.warn(e);
                            props.enqueueSnackbar("Could not save zone name", { variant: "error" });
                        });
                } catch (e) {
                    console.warn(e);
                    props.enqueueSnackbar("Could not save zone name", { variant: "error" });
                }
            },
            3000,
            false,
            true,
        );
    }
    const varietal = props.Target?.userMetadata?.varietal;
    let avatar;
    if (varietal) {
        avatar = <VarietalAvatar varietal={varietal} />;
    }

    if (props.Target.name) {
        name = props.Target.name;
    }
    const mapEntity = props.mapEntity || props.MapEntity;
    return (
        <FunctionalInfoCardEffect
            currentInfoCardType={INFO_CARD_TYPES.ZONE}
            focusContext={focusContextGenerator}
            mapEntity={mapEntity}
            Target={props.Target}
            key={`TempKey-${props.Target.id}`}
            title={
                <TextField
                    style={{
                        width: "100%",
                    }}
                    variant={"standard"}
                    defaultValue={props.Target.name}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onChange={(e) => {
                        const a = { ...e };
                        props.Target.name = a.target.value;
                        bufferedSaveHandler.current.trigger(a.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={"end"}>
                                <EditIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            }
            avatar={avatar}
            name={name}
            contentComponent={ZoneInfoCardBody}
            minHeightExpression={() => "300px"}
        />
    );
});
export const FunctionalZoneInfoCard = withSnackbar(_FunctionalZoneInfoCard);
