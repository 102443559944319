import * as VerdiAPI from "verdiapi";
import { DeviceAPI } from "verdiapi/dist/APICommands";

import { URLParams } from "../../utils/URLParams";
import FocusContext from "../mapManagement/FocusContext";

export function setupVerdiAPI() {
    let endpoint: string = process.env.REACT_APP_BACKEND_URL ?? "api.verdiag.com/api";
    // Add a trailing slash to the endpoint if it doesn't have one
    if (endpoint.charAt(endpoint.length - 1) !== "/") {
        endpoint = `${endpoint}/`;
    }

    // Set the endpoints in VerdiAPI's SessionHandler
    VerdiAPI.SessionHandler.endpoint = endpoint;

    // Log the endpoints being used
    console.info(`Set up verdi API, endpoint: ${endpoint}`);
}

let alreadySetAccount = false;

/**
 * Sets the curUser appropriately from the URL or from the login token and starts the session load
 */
export function setAccountAndStartSessionLoad() {
    // this should run exactly once. If it runs more than once it could throw people back to a page they already left.
    if (alreadySetAccount) {
        return;
    }

    alreadySetAccount = true;
    // if this isn't an admin, then the account is just whatever their account is.
    if (!VerdiAPI.SessionHandler.admin) {
        console.info(`starting load handler 1 ${Date.now() % 10000}`);
        FocusContext.startLoadHandler("Loading Account", "sessionLoad");
        VerdiAPI.SessionHandler.loadSession().then();
        return;
    }

    const accountParam = URLParams.getParam("account");
    // if it is an admin and there is an account parameter set, then we should use that
    if (accountParam) {
        VerdiAPI.SessionHandler.curUser = accountParam;
        FocusContext.startLoadHandler("Fetching Account", "settingAccount");
        VerdiAPI.SessionHandler.changeUser(accountParam)
            .then(() => {
                FocusContext.resolveLoadHandler("settingAccount");
            })
            .catch((e) => {
                console.warn("Error setting account", e);
            });
        return;
    }

    const deviceParam = URLParams.getParam("dev");

    // Admins may have no account parameter, but have a device set. Figure out the account based on the device
    if (
        deviceParam &&
        !URLParams.getParam("account") &&
        VerdiAPI.SessionHandler.admin &&
        !VerdiAPI.SessionHandler.curUser
    ) {
        console.info("Looking for device owner...");

        DeviceAPI.debuggingDeviceQuery(deviceParam).then((r) => {
            if (r && r.DeviceDoc && r.DeviceDoc.owner) {
                console.info("got device owner, setting url param");
                URLParams.setParam("account", r.DeviceDoc.owner);
                VerdiAPI.SessionHandler.changeUser(r.DeviceDoc.owner)
                    .then(() => {
                        FocusContext.resolveLoadHandler("settingAccount");
                    })
                    .catch((e) => {
                        console.warn("Error setting account", e);
                    });
            } else {
                console.info(`failed to find owner for devie with id ${deviceParam}`);
            }
        });
    }
}
