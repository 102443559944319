import { ListSubheader } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { menuClasses } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select, { selectClasses } from "@mui/material/Select";
import React, { useState } from "react";

export function FilledSelect({
    labelId,
    label,
    selectProps,
    formControlProps,
    defaultOptionLabel,
    selectOptions,
    onSelect,
    value,
    backgroundColor = "#fff",
    inputLabelProps,
}) {
    // Having trouble with with the click open/close when uncontrolled. Using controlled for now
    const [open, setOpen] = useState(false);

    // Merge sx if provided
    const { sx: sxFormControl, ...restFormControlProps } = formControlProps || {};
    const { sx: sxSelect, ...restSelectProps } = selectProps || {};

    return (
        <FormControl
            hiddenLabel={label === undefined}
            variant={"filled"}
            sx={{
                backgroundColor: backgroundColor,
                minWidth: "100px",
                ...sxFormControl,
            }}
            {...restFormControlProps}
        >
            {label && (
                <InputLabel id={labelId} {...inputLabelProps}>
                    {label}
                </InputLabel>
            )}
            <Select
                open={open}
                onOpen={() => setOpen(!open)}
                disableUnderline
                labelId={labelId}
                label={label}
                onChange={onSelect}
                onClose={() => setOpen(false)}
                value={value}
                MenuProps={{
                    sx: {
                        top: 5, // Aligns the dropdown with the input
                        [`& .${menuClasses.list}`]: {
                            background: backgroundColor,
                        },
                    },
                }}
                sx={{
                    "color": "black",
                    "backgroundColor": backgroundColor,
                    [`& .${selectClasses.select}`]: {
                        "background": backgroundColor,
                        // Vertically center text when no label
                        "mt": label ? "0px" : "2px",

                        // Normally clicking in (focusing) the dropdown causes a bg color change. We override this to keep it white
                        "&:focus": {
                            background: backgroundColor,
                        },
                    },
                    "& .MuiSelect-icon": {
                        // Position arrow icon so it aligns with the selected text (not centered generally)
                        marginTop: label ? "8px" : "0",
                    },
                    ...sxSelect,
                }}
                {...restSelectProps}
            >
                {defaultOptionLabel && (
                    <MenuItem key={defaultOptionLabel} value={defaultOptionLabel}>
                        <em>{defaultOptionLabel}</em>
                    </MenuItem>
                )}

                {selectOptions &&
                    selectOptions.map((option) =>
                        option.subheader ? (
                            <ListSubheader
                                key={option.id}
                                sx={{ backgroundColor: "#f0f0f0", fontWeight: "bold", color: "#333" }}
                            >
                                {option.name}
                            </ListSubheader>
                        ) : (
                            <MenuItem key={option.id} value={option.value}>
                                {option.name}
                            </MenuItem>
                        ),
                    )}
            </Select>
        </FormControl>
    );
}
