import { OpenInNew } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Checkbox, FormControlLabel, IconButton, Link, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { PRODUCT_UPDATE_PAGE_URL } from "../constants";

interface ProductUpdateModalProps {
    children: React.ReactNode;
    onClose: () => void;
    onDismiss: () => void;
    date: Date;
    showDismissCheckbox?: boolean;
}

/**
 * Modal acting as a container (with header and footer) for displaying product update.
 */
export function ProductUpdateModal({
    children,
    onClose,
    onDismiss,
    date,
    showDismissCheckbox = false,
}: ProductUpdateModalProps) {
    const { t } = useTranslation("modules", { keyPrefix: "productUpdate.productUpdateModal" });

    const [dontShowAgain, setDontShowAgain] = useState(false);

    const handleDismiss = () => {
        if (dontShowAgain) {
            onDismiss();
        }
        onClose();
    };

    return (
        <Stack sx={{ p: 3, backgroundColor: grey[100], overflowY: "hidden" }}>
            <Stack pb={3} px={3} gap={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography sx={{ color: grey[500], fontSize: 12, fontWeight: 450 }}>
                    {date.toLocaleString(undefined, { month: "short" })} {date.getDate()}
                </Typography>
                <Link
                    target={"_blank"}
                    href={PRODUCT_UPDATE_PAGE_URL}
                    sx={{
                        "color": grey[700],
                        "textDecoration": "none",
                        "display": "flex",
                        "alignItems": "center",
                        "justifyContent": "center",
                        "gap": 1,
                        "fontSize": 12,
                        "fontWeight": 500,
                        "&:hover": { textDecoration: "none", color: "primary.main" },
                    }}
                >
                    {t("allUpdates")}
                    <OpenInNew sx={{ fontSize: isMobile ? 10 : 12 }} />
                </Link>
                <IconButton
                    onClick={onClose}
                    aria-label={"close modal"}
                    sx={{ "color": grey[500], "p": 1, "&:hover": { color: "black" } }}
                >
                    <CloseIcon sx={{ fontSize: 16 }} />
                </IconButton>
            </Stack>
            <Stack
                sx={{
                    p: 8,
                    gap: 8,
                    overflowY: "auto",
                    boxShadow: 1,
                    border: 1,
                    borderColor: grey[300],
                    borderRadius: 1.5,
                    backgroundColor: "white",
                }}
            >
                {children}
                <Stack direction={"column"} sx={{ borderTop: "1px solid #e0e0e0", pt: 4 }}>
                    {showDismissCheckbox && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    sx={{
                                        mb: "1px", // Vertically align with label
                                    }}
                                    checked={dontShowAgain}
                                    onChange={(e) => setDontShowAgain(e.target.checked)}
                                />
                            }
                            label={t("dismissCheckboxLabel")}
                        />
                    )}
                    <Button variant={"contained"} onClick={handleDismiss} sx={{ minWidth: 180, mt: 2, mx: "auto" }}>
                        {t("getStarted")}
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
}
