import { SessionHandler } from "verdiapi/dist/APICommands/SessionHandler";
import type { ModelBase } from "verdiapi/dist/Models";
import { DeviceConfiguration, DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

/**
 * TODO: find a better home for this file. Probably not suited to the mapEntities folder.
 */
import {
    badgeColorMapping,
    disabledIconColorMapping,
    iconColorMapping,
    notesColorMapping,
} from "../../../components/icons/MapIcons/DeviceIcon/constants";
import {
    BADGE_VARIANT,
    BadgeParams,
    ColorParams,
    ICON_VARIANT,
    NOTES_OVERRIDE_COLOR,
    ProgressRingParams,
    WARNING_STATUS,
} from "../../../components/icons/MapIcons/DeviceIcon/types";
import { isValve } from "../../deviceDataAccessors/deviceAttributes";
import { composeDeviceState } from "../../deviceDataAccessors/deviceDataAccessors";
import {
    NOTES_CUSTOM_ERROR_PREFIX,
    NOTES_CUSTOM_WARNING_PREFIX,
    NOTES_DISABLED_DEVICE_TEXT,
    NOTES_OVERRIDE_COLOR_PREFIX,
    NOTES_SUPPRESS_WARNINGS_TEXT,
} from "../iconManager/notes";
import type MapEntityBase from "./MapEntityBase";

interface WarningsParams {
    model: MapEntityBase["model"];
    type: DeviceConfiguration["type"];
}

export const WARNING_STATUS_PRIORITY = {
    [WARNING_STATUS.WARNING]: 1,
    [WARNING_STATUS.ERROR]: 2,
} as const;

export interface WarningsObj {
    status: WARNING_STATUS | undefined;
    warningText: string | undefined;
    warningToShow:
        | {
              warningType: string;
              warningTitle: string;
              warningLevel: number;
              warningTypePriority: number;
          }
        | undefined;
    isSensorOutOfDate: boolean | undefined;
    manualModeWarning: boolean | undefined;
}
export const makeWarnings = ({ model, type }: WarningsParams): WarningsObj => {
    const warnings: WarningsObj = {
        status: undefined,
        warningText: undefined,
        warningToShow: undefined,
        manualModeWarning: undefined,
        isSensorOutOfDate: undefined,
    };

    /**
     * No warnings for:
     * - seed devices
     * - recently created devices (often false positive error for first hours)
     * - devices that are disabled or suppressed via notes
     * - an admin account has used their admin-only "hideRedDevicesOnAdmin" in the device settings menu
     */
    const recentlyCreated = model.createdAt && Date.now() - model.createdAt.valueOf() < 2 * 1000 * 60 * 60;
    if (
        DeviceConfigurationsByType[type]?.generation === "seed" ||
        disabledByNotes(model.notes) ||
        recentlyCreated ||
        suppressWarningsByNotes(model.notes) ||
        SessionHandler.currentUserObject?.webPreferences?.hideRedDevicesOnAdmin
    ) {
        return warnings;
    }

    if (model.type && model.type === "msense") {
        let isSensorOutOfDate = false;
        if (model.recentData && model.recentData.moisture) {
            if (Math.abs(Date.now() - new Date(model.recentData.moisture.date).valueOf()) > 1000 * 60 * 60 * 24 * 5) {
                isSensorOutOfDate = true;
            }
        }

        warnings.isSensorOutOfDate = isSensorOutOfDate;
        if (isSensorOutOfDate) {
            warnings.status = WARNING_STATUS.WARNING;
        }
    }

    // A lot of code here is just to seperate out manual override warnings
    // we are temporarily making the manual mode warnings present differently, this will be changed in
    // the future.
    let noManualWarnings = [...(model.warnings || [])];
    noManualWarnings = noManualWarnings.filter((w) => w.warningType !== "Manual Override Engaged");
    warnings.manualModeWarning = (model.warnings || []).some((w: any) => w.warningType === "Manual Override Engaged");
    if (noManualWarnings.length > 0) {
        if (noManualWarnings[0].warningLevel > 0) {
            warnings.status = noManualWarnings[0].warningLevel > 1 ? WARNING_STATUS.ERROR : WARNING_STATUS.WARNING;
            warnings.warningText = noManualWarnings[0].warningTitle;
            warnings.warningToShow = noManualWarnings[0];
        }
    }

    // Notes warnings have lower priority than normal device warnings/errors
    if (!warnings.warningText && model.notes) {
        if (warningByNotes(model.notes)) {
            warnings.warningText = "check notes";
            warnings.status = WARNING_STATUS.WARNING;
            warnings.warningToShow = {
                warningType: "custom warning",
                warningTitle: "check notes",
                warningLevel: 1,
                warningTypePriority: 1,
            };
        } else if (errorByNotes(model.notes)) {
            warnings.warningText = "check notes";
            warnings.status = WARNING_STATUS.ERROR;
            warnings.warningToShow = {
                warningType: "custom error",
                warningTitle: "check notes",
                warningLevel: 2,
                warningTypePriority: 1,
            };
        }
    }

    return warnings;
};

export const shouldExpand = ({
    model,
    warningToShow,
    warningStatus,
}: {
    model: MapEntityBase["model"];
    warningToShow: WarningsObj["warningToShow"];
    warningStatus: WarningsObj["status"];
}) => {
    const { manualModeState, isIrrigating } = composeDeviceState({ model });
    return manualModeState.isManualMode || isIrrigating || (warningToShow !== undefined && warningStatus !== undefined);
};

export const disabledByNotes = (notes?: string) => notes?.toLowerCase().includes(NOTES_DISABLED_DEVICE_TEXT);
export const suppressWarningsByNotes = (notes?: string) => notes?.toLowerCase().includes(NOTES_SUPPRESS_WARNINGS_TEXT);
export const warningByNotes = (notes?: string) => notes?.toLowerCase().includes(NOTES_CUSTOM_WARNING_PREFIX);
export const errorByNotes = (notes?: string) => notes?.toLowerCase().includes(NOTES_CUSTOM_ERROR_PREFIX);

export const makeColorParams = ({
    deviceType,
    notes,
}: {
    deviceType: DeviceConfiguration["type"];
    notes: string;
}): ColorParams => {
    if (disabledByNotes(notes)) {
        return disabledIconColorMapping;
    }

    if (notes) {
        const colors = Object.values(NOTES_OVERRIDE_COLOR);
        const color = colors.find((c) => notes.toLowerCase().includes(`${NOTES_OVERRIDE_COLOR_PREFIX}${c}`));
        if (color) {
            return notesColorMapping[color];
        }
    }

    if (isValve({ deviceType })) {
        return iconColorMapping[ICON_VARIANT.VALVE];
    }
    return iconColorMapping[ICON_VARIANT.SENSOR];
};

/**
 * Determine if a group should show a progress ring
 */
export const makeGroupProgressRingParams = ({ models }: { models: ModelBase[] }): ProgressRingParams => {
    const someMemberHasIrrigationRing = models.some((model) => {
        const { progressRing } = makeProgressRingParams({ model });
        return progressRing;
    });
    if (someMemberHasIrrigationRing) {
        return {
            progressRing: true,
            progressRingOptions: {
                spinning: false,
                percentage: 100,
            },
        };
    }
    return {
        progressRing: false,
    };
};

export const makeProgressRingParams = ({ model }: { model: MapEntityBase["model"] }): ProgressRingParams => {
    const { manualModeState, isIrrigating, irrigationEventState } = composeDeviceState({
        model,
    });

    const { percentageOfIrrigationCompleted } = irrigationEventState;

    if (manualModeState.isTransitioning) {
        return {
            progressRing: true,
            progressRingOptions: {
                spinning: true,
                percentage: 0,
            },
        };
    }

    if (isIrrigating) {
        // Show progress ring at set percentage
        if (percentageOfIrrigationCompleted !== null) {
            return {
                progressRing: true,
                progressRingOptions: {
                    spinning: false,
                    percentage: percentageOfIrrigationCompleted,
                },
            };
        }
        // Show progress ring if irrigating (valve is open)
        return {
            progressRing: true,
            progressRingOptions: {
                spinning: false,
                percentage: 100,
            },
        };
    }
    return {
        progressRing: false,
        progressRingOptions: undefined,
    };
};

export const makeBadgeParams = ({
    warningStatus,
    size,
}: {
    warningStatus?: WARNING_STATUS;
    size?: number;
}): BadgeParams => {
    if (warningStatus && warningStatus === WARNING_STATUS.ERROR) {
        return {
            badge: true,
            badgeOptions: {
                bgColor: badgeColorMapping[BADGE_VARIANT.ERROR].bg,
                borderColor: badgeColorMapping[BADGE_VARIANT.ERROR].border,
                size,
            },
        };
    }

    if (warningStatus && warningStatus === WARNING_STATUS.WARNING) {
        return {
            badge: true,
            badgeOptions: {
                bgColor: badgeColorMapping[BADGE_VARIANT.WARNING].bg,
                borderColor: badgeColorMapping[BADGE_VARIANT.WARNING].border,
                size,
            },
        };
    }

    return {
        badge: false,
    };
};
