import "./GraphDemoPage.scss";

import Typography from "@mui/material/Typography";
import React from "react";
import { MasterIndex, SessionHandler } from "verdiapi";

import DynamicGraph from "../components/specialized/DynamicGraph/DynamicGraph";
import { URLParams } from "../utils/URLParams";

// TODO: Delete this file in 2026, we should have the new graphs done by then
// This is a hack feature to create ad hoc graphs for certain customers
export default class CustomGraphPage extends React.Component {
    loaded = false;

    constructor(props) {
        super(props);
        this.state = {
            displayedZonesList: [],
            displayedDevicesList: [],
        };
        this.endTimeForGraph = new Date(Date.now());
        this.startTimeForGraph = new Date(this.endTimeForGraph - 1000 * 60 * 60 * 24 * 30);
    }

    componentDidMount() {
        this.loadURLParamZones();
        SessionHandler.onSessionLoad.addListener(() => {
            this.loadURLParamZones();
        });
    }

    loadURLParamZones() {
        let allSelectedZones = URLParams.getParam("zones");
        const selectedAOI = URLParams.getParam("aoi");
        const newZoneList = [...(this.state.displayedZonesList || [])];
        if (allSelectedZones) {
            allSelectedZones = allSelectedZones.replaceAll("%7C", "|");
            allSelectedZones = allSelectedZones.split("|");

            allSelectedZones.forEach((z) => {
                const newZoneToAdd = MasterIndex.zone.all.find((zone) => zone.dbID === z);
                if (newZoneToAdd && !newZoneList.includes(newZoneToAdd)) {
                    newZoneList.push(newZoneToAdd);
                }
            });
        }
        if (selectedAOI) {
            const linkedAOI = MasterIndex.aoi.byID[selectedAOI];

            MasterIndex.zone.all.forEach((zone) => {
                if (zone.linkedAreaOfInterest === linkedAOI) {
                    if (zone && !newZoneList.includes(zone)) {
                        newZoneList.push(zone);
                    }
                }
            });
        }

        if (newZoneList.length > 0) {
            this.loaded = true;
        }
        this.setState({ displayedZonesList: newZoneList });
    }

    render() {
        return (
            <div className={"GraphDemoPageContainer"}>
                <div className={"GraphDemoTitle"}>
                    <Typography variant={"h3"}>Custom Graph</Typography>
                </div>
                {this.loaded && (
                    <DynamicGraph
                        hideIrrigationBars={this.state.displayedZonesList.length > 2}
                        startTime={this.startTimeForGraph}
                        endTime={this.endTimeForGraph || new Date(Date.now())}
                        zoneList={this.state.displayedZonesList}
                        // sensorList={}
                        deviceList={this.state.displayedDevicesList}
                        graphDataType={"sensoterra"}
                        startingWidth={300}
                        canvasHeight={275}
                    />
                )}
            </div>
        );
    }
}
